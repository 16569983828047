import React from "react";

import { Grid, Cell } from "../../components/ui";
import { FormattedContentItem } from "../../components/content";

import { useStyletron } from "baseui";
import { HeadingXXLarge, LabelMedium, DisplayLarge } from "baseui/typography";

import planet from "../../assets/images/planet.svg";
import rocket from "../../assets/images/rocket.svg";
import astronomer from "../../assets/images/astronomer.svg";

import { useTheme } from "../../contexts/theme";

const Alert = () => {
  const [css, theme] = useStyletron();
  const { useDarkTheme } = useTheme();

  return (
    <article
      data-csweb="404-alert"
      className={css({
        backgroundColor: theme.colors.backgroundSecondary,
        minHeight: "100vh",
      })}
    >
      <Grid>
        <Cell span={12}>
          <div
            className={css({
              minHeight: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              position: "relative",
            })}
          >
            <img
              alt="Planet"
              src={planet}
              className={css({
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-52%, -50%)",
                height: "620px",
              })}
            />
            <img
              alt="Astronomer"
              src={astronomer}
              className={css({
                position: "absolute",
                right: "10%",
                top: "40%",
                transform: "translate(-50%, -50%)",
              })}
            />
            <img
              alt="Rocket"
              src={rocket}
              className={css({
                position: "absolute",
                left: "10%",
                top: "60%",
                transform: "translate(-50%, -50%)",
              })}
            />
            <DisplayLarge
              color="primary"
              $style={{
                textAlign: "center",
                fontSize: "78px",
                position: "relative",
              }}
            >
              <FormattedContentItem scope="Errors" accessor="404.code" />
            </DisplayLarge>
            <HeadingXXLarge
              color="black"
              $style={{ textAlign: "center", position: "relative" }}
              marginBottom="scale600"
            >
              <FormattedContentItem scope="Errors" accessor="404.title" />
            </HeadingXXLarge>
            <LabelMedium
              color="black"
              $style={{
                textAlign: "center",
                fontWeight: "400",
                position: "relative",
              }}
            >
              <FormattedContentItem scope="Errors" accessor="404.subtitle" />
            </LabelMedium>
          </div>
        </Cell>
      </Grid>
    </article>
  );
};

export default Alert;
