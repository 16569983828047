import React from "react";

import { Layout } from "../../components/layout";
import { SEO } from "../../components/meta";

import Alert from "./404-alert";

const Error404 = () => (
  <>
    <SEO title="Page not found" />

    <Layout header={{ position: "absolute", size: "compact" }} footer={false}>
      <section data-csweb="404">
        <Alert />
      </section>
    </Layout>
  </>
);

export default Error404;
